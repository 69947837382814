import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react";

import Carousel, { Modal, ModalGateway } from "react-images";

// <-- swiper start
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./swiper.css";

import { FreeMode, Navigation, Thumbs } from "swiper";

// swiper end -->

import { Video } from "gatsby-video";

import { Button } from "gatsby-theme-material-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "@material-ui/core/Grid";
import TextInfoContent from "@mui-treasury/components/content/textInfo";

import Layout from "../components/Layout";
import Container from "../components/Container";

const renderAst = new rehypeReact({
    createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2em",
    },
    body: {
        textAlign: "justify",
    },
    action: {
        display: "flex",
        justifyContent: "center",
        maxWidth: "200px",
    },
}));

const WineMakersPage = ({
    data: {
        allMarkdownRemark: { edges: content },
        galleryContent: { edges: gallery },
    },
}) => {
    const styles = useStyles();

    const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
    const photos = [];
    /*
    const photos = gallery.map((img) => ({
        id: img.node.childImageSharp.id,
        width: img.node.childImageSharp.gatsbyImageData.width,
        height: img.node.childImageSharp.gatsbyImageData.height,
        src: img.node.childImageSharp.gatsbyImageData.images.fallback.src,
        srcSet: img.node.childImageSharp.gatsbyImageData.images.fallback.srcSet,
        sizes: img.node.childImageSharp.gatsbyImageData.images.fallback.sizes,
    }));
    */

    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <Layout pageName="daytrips">
            <Helmet>
                <title>All Inclusive Meet the Winemakers Tour</title>
            </Helmet>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <Container maxWidth="md" type="content">
                <Grid container className={styles.root} spacing={4}>
                    {content.map((c) => (
                        <>
                            {/* xs, sm, md, lg, and xl. */}
                            <Grid item xs={12} md={6} key={`g-content`}>
                                <TextInfoContent
                                    bodyProps={{ className: styles.body }}
                                    overline={c.node.frontmatter.overline}
                                    heading={c.node.frontmatter.title}
                                    body={renderAst(c.node.htmlAst)}
                                />
                                <Button
                                    className={styles.action}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        window.$chatwoot.reset();
                                        window.$chatwoot.setCustomAttributes({
                                            inquiry: "winevines",
                                        });
                                        window.$chatwoot.toggle("open");
                                    }}
                                >
                                    {c.node.frontmatter.actionTitle}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} key={`g-image`}>
                                <Swiper
                                    style={{
                                        "--swiper-navigation-color": "#fff",
                                        "--swiper-pagination-color": "#fff",
                                    }}
                                    loop={true}
                                    spaceBetween={10}
                                    navigation={true}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"
                                >
                                    {gallery.map(({ node: m }) => {
                                        if (m.remux) {
                                            return (
                                                <SwiperSlide>
                                                    <Video
                                                        autoPlay
                                                        muted
                                                        controls
                                                        loop
                                                        sources={[
                                                            m.remux.webm,
                                                            m.remux.mp4,
                                                        ]}
                                                    />
                                                </SwiperSlide>
                                            );
                                        } else {
                                            return (
                                                <SwiperSlide>
                                                    <GatsbyImage
                                                        image={getImage(
                                                            m.childImageSharp
                                                                .gatsbyImageData
                                                        )}
                                                        alt="topic image"
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    })}
                                </Swiper>

                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    loop={false}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper"
                                >
                                    {gallery.map(({ node: m }) => {
                                        if (m.remux) {
                                            return (
                                                <SwiperSlide>
                                                    <Video
                                                        autoPlay
                                                        muted
                                                        controls
                                                        loop
                                                        sources={[
                                                            m.remux.webm,
                                                            m.remux.mp4,
                                                        ]}
                                                    />
                                                </SwiperSlide>
                                            );
                                        } else {
                                            return (
                                                <SwiperSlide>
                                                    <GatsbyImage
                                                        image={getImage(
                                                            m.childImageSharp
                                                                .gatsbyImageData
                                                        )}
                                                        alt="topic image"
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    })}
                                </Swiper>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Container>
        </Layout>
    );
};

export default WineMakersPage;

export const WineMakersPageQuery = graphql`
    query WineMakersPageQuery {
        galleryContent: allFile(
            filter: { sourceInstanceName: { eq: "winemakers-gallery" } }
            sort: { fields: childVideoFfmpeg___id }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            height: 1008
                            width: 756
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                    remux: childVideoFfmpeg {
                        webm: transcode(
                            codec: "libvpx-vp9"
                            maxWidth: 900
                            maxHeight: 480
                            fileExtension: "webm"
                            outputOptions: ["-crf 20", "-b:v 0"]
                        ) {
                            width
                            src
                            presentationMaxWidth
                            presentationMaxHeight
                            originalName
                            height
                            fileExtension
                            aspectRatio
                        }
                        mp4: transcode(
                            codec: "libx264"
                            maxWidth: 900
                            maxHeight: 480
                            fileExtension: "mp4"
                            options: [
                                ["-profile:v", "main"]
                                ["-pix_fmt", "yuv420p"]
                            ]
                            outputOptions: ["-movflags faststart"]
                        ) {
                            width
                            src
                            presentationMaxWidth
                            presentationMaxHeight
                            originalName
                            height
                            fileExtension
                            aspectRatio
                        }
                    }
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "page-winemakers" } } }
            limit: 1
        ) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        actionTitle
                        overline
                    }
                }
            }
        }
    }
`;
